import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { withPopups } from "react-popup-manager";
import moment from 'moment';
import 'moment/locale/de';

import Button from '../../parts/Button';
import ContextMenu from '../../parts/ContextMenu';
import { Table, Row, HeaderRow, Cell } from '../../parts/Table';
import SendNotification from '../../modals/SendNotification';

moment.locale('de');

const Users = props => {
  useEffect(() => {
    props.fetchUsers();
  }, []);

  const [ activeUser, setActiveUser ] = useState(false);

  const openModal = (e, user) => {
    e.preventDefault();
    console.log(user);
    props.popupManager.open(SendNotification, {
      title: 'Benachrichtigung an Kunden senden',
      data:{
        user
      },
      onClose: (...params) => console.log('modal has closed with:', ...params)});
  }

  if(!props.users){
    return false;
  }

  const mappedUsers = props.users.map(user => ({
    'Name': `${user.firstName} ${user.lastName}`,
    'Firma': '',
    'E-Mail-Adresse': user.email,
    'Status': user.status,
    'angelegt': moment(user.createdAt).fromNow(),
  }));

  return (
    <div>
      <h1 className="single__title">Kunden</h1>
      <div className="single__actions">
        <Button to={`/management/users/new`} type="primary" color="grey" size="small">Neuer Kunde</Button>
      </div>

      {mappedUsers.length > 0 &&
        <Table className="users">
          <HeaderRow>
            {Object.keys(mappedUsers[0]).map((prop, i) =>
              <Cell key={i}>{prop}</Cell>
            )}
          </HeaderRow>
          {mappedUsers.map((user, i) => (
            <Row className={activeUser === i ? 'active' : null} key={i} to={`/management/users/${props.users[i]._id}`}>
              {Object.keys(user).map((prop, i) =>
                <Cell key={i}>{user[prop]}</Cell>
              )}
              <Cell>
              </Cell>
              <Cell className="actions">
                <ContextMenu onOpen={() => setActiveUser(i)} onClose={() => setActiveUser(false)} options={[
                  {
                    label: 'Neues Objekt',
                    onClick: e => {e.preventDefault();props.history.push(`management/users/${props.users[i]._id}/properties/new`)}
                  },
                  {
                    label: 'Benachrichtigung senden',
                    onClick: e => openModal(e, user),
                  },
                  {
                    label: 'Kunden löschen',
                    color: 'red',
                    onClick: e => e.preventDefault(),
                    disabled: true
                  },
                ]} />
              </Cell>
            </Row>
          ))}
        </Table>
      }
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    users: state.admin.users.users,
  }
}

export default connect(mapStateToProps, actions)(withPopups()(Users));
