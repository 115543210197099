import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PopupProvider } from "react-popup-manager";
import ReduxThunk from 'redux-thunk';
// import ReactGA from 'react-ga';
import logger from 'redux-logger';
import * as serviceWorker from './js/serviceWorker';
import { createStore, applyMiddleware } from 'redux';
import { fetchLoggedInUserData } from './js/actions';
import reducers from './js/reducers';

import App from './js/components/App';


const store =  createStore(reducers, applyMiddleware(logger, ReduxThunk));
const token = localStorage.getItem('hansAuthToken');

if(token){
	store.dispatch({ type: 'AUTH_USER' });
	store.dispatch(fetchLoggedInUserData(token));
}

ReactDOM.render(<Provider store={store}>
  <BrowserRouter>
		<PopupProvider>
    	<App />
		</PopupProvider>
  </BrowserRouter>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
