import React, { useState, useEffect } from 'react';
import Button from '../parts/Button';
import Input from '../parts/Input';
import { Close } from '../parts/Svg';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { withPopups } from "react-popup-manager";
import SuccessModal from './SuccessModal';

const SendNotification = props => {
  const [ email, setEmail ] = useState(props.data.user['E-Mail-Adresse']);
  const [ firstName, setFirstName ] = useState(props.data.user['Vorname']);
  const [ lastName, setLastName ] = useState(props.data.user['Nachname']);
  const [ template, setTemplate ] = useState(false);

  const close = () => {
    props.onClose('param', 'param2', 'param3');
  }

  const _handleSubmit = () => {
    // TODO: adapt once naming is correct
    props.sendEmail({
      to: email,
      templateId: template,
      'dynamic_template_data': {
        firstname: firstName,
        lastname: lastName
      }
    });
  }
  // TODO: add ESC keyhandler

  
  useEffect(() => {
    if(props.success && !props.loading){
      close();
      props.popupManager.open(SuccessModal, {
        title: 'Nachricht gesendet',
        body: 'Die Notification wurde erfolgreich an den Kunden gesendet'
      });
    }
  }, [props.success, props.loading]);


  return <div className="modal">
    <div className="modal__box">
      <div className="modal__box__head">
        <span>{props.title || ''}</span>
        <button onClick={() => close()} className="close">
          <Close />
        </button>
      </div>
      <div className="modal__box__body">
        <form onSubmit={() => _handleSubmit()}>
          <div className="field field--select">
            <select required name="template" defaultValue="" onChange={e => setTemplate(e.currentTarget.value)}>
              <option disabled value="">Vorlage auswählen</option>
              <optgroup label="E-Mail">
                <option value="d-65fb382c18d24188a61808b6948ffccf">Bewerte uns</option>
                <option value="d-01bb84364bdf42478ae8e4292bb0f3e6">Auf dem Weg</option>
              </optgroup>
              <optgroup label="SMS">
                <option value="" disabled>Auf dem Weg</option>
              </optgroup>
            </select>
          </div>
          <br />
          <Input required type="email" name="email" defaultValue={email} onChange={e => setEmail(e.currentTarget.value)} placeholder="E-Mail-Adresse" />
          <Input required type="text" name="firstname" defaultValue={firstName} onChange={e => setFirstName(e.currentTarget.value)} placeholder="Vorname" />
          <Input required type="text" name="lastname" defaultValue={lastName} onChange={e => setLastName(e.currentTarget.value)} placeholder="Nachname" />
        </form>

      </div>
      <div className="modal__box__footer">
        <Button type="primary" size="small" onClick={() => _handleSubmit()}>Confirm</Button>
      </div>
    </div>
  </div>;
}

const mapStateToProps = (state, ownProps) => {
  const { loading, success } = state.admin.notifications;

  return {
    loading,
    success
  }
}

export default connect(mapStateToProps, actions)(withPopups()(SendNotification));
