import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

export default props => {
  const [ visible, setVisible ] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    document.addEventListener('mouseup', handleOutsideClick, false);

    return function cleanup() {
      document.removeEventListener('mouseup', handleOutsideClick, false);
    };
  });

  const handleOutsideClick = e => {
    setVisible(false);
  }

  const handleToggleClick = e => {
    e.preventDefault();
    setVisible(true);
  }

  return (
    <div className="context-menu" ref={ref}>
      <div className="context-menu__toggle" onClick={e => handleToggleClick(e)}>
        <span />
        <span />
        <span />
      </div>

      <div className={classNames('context-menu__menu', {'context-menu__menu--visible': visible})} >
      {props.options && <ul>
        {props.options.map((option, i) => {
          return (
            <li
              key={i}
              className={classNames(option.color, {'disabled': option.disabled})}
              onClick={option.onClick}
              >
              {option.label}
            </li>
          )
        })}
        </ul>
      }
      </div>
    </div>
  );
}
