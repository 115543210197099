import axios from 'axios';
import { tokenHeader } from '../authentication';

export function fetchAllProperties(){
	return dispatch => {
		dispatch({type: 'FETCH_ALL_PROPERTIES_REQUEST'});
		axios.get(`${process.env.REACT_APP_API_BASE}/admin/properties?sortBy=createdAt:desc`, tokenHeader())
		.then(response => {
      dispatch({
				type: 'FETCH_ALL_PROPERTIES_SUCCESS',
				properties: response.data
			});
		})
		.catch(error => {
			dispatch({type: 'FETCH_ALL_PROPERTIES_ERROR'});
		});
	}
}

export function fetchUserProperties(userId){
	return dispatch => {
		dispatch({type: 'FETCH_USER_PROPERTIES_REQUEST'});
		axios.get(`${process.env.REACT_APP_API_BASE}/admin/users/${userId}/properties`, tokenHeader())
		.then(response => {
      dispatch({
				type: 'FETCH_USER_PROPERTIES_SUCCESS',
				id: userId,
				properties: response.data
			});
		})
		.catch(error => {
			dispatch({
				type: 'FETCH_USER_PROPERTIES_ERROR',
				error
			});
		});
	}
}

export function createUserProperty(data){
	return (dispatch) => {
		dispatch({type: 'CREATE_USER_PROPERTY_REQUEST'});
    axios.post(`${process.env.REACT_APP_API_BASE}/admin/${data.userId}/properties`, data.property, tokenHeader())
    .then(response => {
      console.log(response);
			dispatch({
				type: 'CREATE_USER_PROPERTY_SUCCESS',
				property: response.data
			});
    })
    .catch((error) =>{
			dispatch({type: 'CREATE_USER_PROPERTY_ERROR'});
    });
  }
}

export function fetchUserProperty(propertyId){
	return dispatch => {
		dispatch({type: 'FETCH_USER_PROPERTY_REQUEST'});
		axios.get(`${process.env.REACT_APP_API_BASE}/admin/properties/${propertyId}`, tokenHeader())
		.then(response => {
      dispatch({
				type: 'FETCH_USER_PROPERTY_SUCCESS',
				property: response.data
			});
		})
		.catch(error => {
			dispatch({type: 'FETCH_USER_PROPERTY_ERROR', error});
		});
	}
}

export function deleteUserProperty(property){
	return (dispatch) => {
		dispatch({type: 'DELETE_USER_PROPERTY_REQUEST'});
    axios.delete(`${process.env.REACT_APP_API_BASE}/admin/properties/${property._id}`, tokenHeader())
    .then(response => {
			dispatch({
				type: 'DELETE_USER_PROPERTY_SUCCESS',
				userId: property.owner,
				property: response.data
			});
    })
    .catch((error) =>{
			dispatch({type: 'DELETE_USER_PROPERTY_ERROR'});
    });
  }
}
