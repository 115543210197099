import React from 'react';
import ScrollContext from '../context/ScrollContext';
import debounce from 'lodash/debounce';

class ScrollProvider extends React.PureComponent {

  constructor(props){
    super(props);

    this._updateFlag = false;
    this._scrollState = {
      scrollPosition: 0,
      offset: 0,
    };
  }

  componentWillUnmount(){
    document.removeEventListener('scroll', debounce(this.scrollListener.bind(this),15))
  }

  componentDidMount(){
    document.addEventListener('scroll', debounce(this.scrollListener.bind(this),15))
  }

  _update = () => {
    const newOffset = window.pageYOffset;

    this._scrollState.scrollPosition = window.pageYOffset / (this._getDocumentHeight() - window.innerHeight);
    this._scrollState.offset = newOffset;

    this.forceUpdate();
    this._updateFlag = true;
  };

  _getDocumentHeight() {
    var D = document;
    return Math.max(
      D.body.scrollHeight, D.documentElement.scrollHeight,
      D.body.offsetHeight, D.documentElement.offsetHeight,
      D.body.clientHeight, D.documentElement.clientHeight
    );
  }

  scrollListener(){
    if(this._updateFlag){
      const newScrollPosition = window.pageYOffset / (this._getDocumentHeight() - window.innerHeight);
      const oldScrollPosition = this._scrollState.scrollPosition;
      const difference = window.pageYOffset - this._scrollState.offset;

      if(newScrollPosition === oldScrollPosition){
        // console.log('same position', newScrollPosition, oldScrollPosition);
        return;
      }

      this._scrollState = {
        scrollPosition: newScrollPosition,
        offset: window.pageYOffset,
        direction: difference > 0 ? 'down' : 'up'
      };
    }
    this._updateFlag = false;
    requestAnimationFrame(this._update);
  }

  render() {
    return (
      <ScrollContext.Provider value={this._scrollState}>
        {this.props.children}
      </ScrollContext.Provider>
      );
  }
}

export default ScrollProvider;
