import React from 'react';
import { connect } from 'react-redux';
import Button, { BackButton } from '../parts/Button';
import Input from '../parts/Input';
import AppPage from '../layouts/AppPage';
import * as actions from '../../actions';

class CreateProperty extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      name: '',
      addressStreet: '',
      addressPostalCode: '',
      addressCity: '',
    }
  }

  componentDidMount(){
    this.props.fetchProperties();
  }

  componentDidUpdate(prevProps){
    if(prevProps.properties.length < this.props.properties.length){
      this.props.history.push(`/properties/${this.props.properties[this.props.properties.length - 1]._id}`);
    }
  }

  _handleSubmit = event => {
    event.preventDefault();

    // TODO: remove hard-coded icon once backend is fixed

    this.props.createProperty({
      ...this.state,
      icon: 'http://portal.hans-hausmeister.de/house.png'
    });
  }

  _handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
  render(){
    return (
      <AppPage>
        <BackButton />

        <form className="create-form" onSubmit={e => this._handleSubmit(e)}>
          <div className="create-form__title">
            <h2>
            Neues Objekt anlegen
            </h2>
          </div>
          <div className="create-form__body">
            <h4>Details</h4>
            <Input required type="text" name="name" onChange={this._handleChange} onKeyUp={this._handleChange} placeholder="Bezeichnung" value={this.state.name}/>

            <br />
            <h4>Adresse</h4>
            <Input required type="text" name="addressStreet" onChange={this._handleChange} onKeyUp={this._handleChange} placeholder="Straße" value={this.state.addressStreet}/>

            <Input required type="text" name="addressPostalCode" onChange={this._handleChange} onKeyUp={this._handleChange} placeholder="Postleitzahl" value={this.state.addressPostalCode}/>

            <Input required type="text" name="addressCity" onChange={this._handleChange} onKeyUp={this._handleChange} placeholder="Stadt" value={this.state.addressCity}/>
          </div>

          <div className="create-form__actions">
            <small className="info-message">
              Sobald du ein neues Objekt anlegst, überprüfen wir die hinterlegten Daten und melden uns bei dir.
            </small>
            {this.props.error &&
              <div className="error-message">{this.props.error}</div>
            }
            <Button type="primary" color="blue">Objekt anlegen</Button>
          </div>
        </form>

      </AppPage>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { properties, loading, error } = state.properties;

  return {
    user: state.authentication.user,
      properties,
      loading,
      error
  }
}


export default connect(mapStateToProps, actions)(CreateProperty);
