import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import AppPage from '../layouts/AppPage';
import Users from './Management/Users';
import UserProperties from './Management/UserProperties';
import User from './Management/User';
import UserProperty from './Management/UserProperty';
import CreateUser from './Management/CreateUser';
import CreateUserProperty from './Management/CreateUserProperty';

const Management = props => {
  const { location } = props;
  return (
    <AppPage submenu={[
        {
          to: '/management',
          label: 'Kunden',
          exact: true
        },
        {
          to: '/management/properties',
          label: 'Objekte',
        },
      ]}>

      <Switch location={location}>
        <Route exact path="/management/users/new" component={CreateUser} />
        <Route path="/management/users/:userId/properties/new" component={CreateUserProperty} />
        <Route path="/management/users/:userId/properties/:propertyId" component={UserProperty} />
        <Route path="/management/users/:id" component={User} />
        <Route path="/management/properties" component={UserProperties} />
        <Route exact path="/management" component={Users} />
      </Switch>

    </AppPage>
  );
}

export default withRouter(Management);
