import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { withPopups } from "react-popup-manager";
import { find } from 'lodash';
import moment from 'moment';
import 'moment/locale/de';
import CreateActivity from '../../modals/CreateActivity';

import ContextMenu from '../../parts/ContextMenu';
import { Table, Row, HeaderRow, Cell } from '../../parts/Table';

moment.locale('de');

const UserProperties = withPopups()(props => {
  useEffect(() => {
    props.fetchAllProperties();
    props.fetchUsers();
  }, []);

  if(!props.properties){
    return false;
  }

  const openActivityModal = (e, property) => {
    e.preventDefault();

    props.popupManager.open(CreateActivity, {
      title: 'Neue Aktivität anlegen',
      data:{ property },
      onClose: (...params) => console.log('modal has closed with:', ...params)
    });
  }

  const mappedProperties = props.properties.map(property => {
    const { owner } =  property;

    return {
      'Name': property.name,
      'Adresse': property.addressStreet,
      'Postleitzahl': property.addressPostalCode,
      'Ort': property.addressCity,
      'Kunde': owner ? `${property.owner.firstName} ${property.owner.lastName}` : undefined,
      'angelegt': moment(property.createdAt).fromNow(),
    }
  });

  return (
    <div>
      <h1 className="single__title">Objekte</h1>

      {mappedProperties.length > 0 &&
        <Table className="properties">
          <HeaderRow>
            {Object.keys(mappedProperties[0]).map((prop, i) =>
              <Cell key={i}>{prop}</Cell>
            )}
          </HeaderRow>
          {mappedProperties.map((mappedProperty, i) => {
            const property = props.properties[i];
            return (
              <Row key={i} to={`/management/users/${property.owner_id}/properties/${property._id}`}>
                {Object.keys(mappedProperty).map((prop, i) =>
                  <Cell key={i}>{mappedProperty[prop]}</Cell>
                )}
                <Cell>
                </Cell>
                <Cell className="actions">
                  <ContextMenu options={[{label: 'Neue Aktivität', onClick: e => openActivityModal(e, property)}]} />
                </Cell>
              </Row>
            );
          })}
        </Table>
      }
    </div>
  );
});

const mapStateToProps = (state, ownProps) => {
  let properties = state.admin.properties.properties;

  if(state.admin.users.users){
    properties = properties.map(property => ({
      ...property,
      owner_id: property.owner,
      owner: find(state.admin.users.users, {_id: property.owner})
    }))


  }
  return {
    properties
  }
}

export default connect(mapStateToProps, actions)(withPopups()(UserProperties));
