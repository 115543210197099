import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export const Table = props => {
  const { className, ...otherProps } = props;
  return (
    <div className={classNames('table', className)} {...otherProps}>
      {props.children}
    </div>
  );
}

export const Row = props => {
  const { className, to, ...otherProps } = props;
  const Component = to ? Link : 'div';
  return (
    <Component to={to} className={classNames('tr', className)} {...otherProps}>
      {props.children}
    </Component>
  );
}

export const HeaderRow = props => {
  const { className, ...otherProps } = props;
  return (
    <div className={classNames('tr tr--header', className)} {...otherProps}>
      {props.children}
    </div>
  );
}

export const Cell = props => {
  const { className, ...otherProps } = props;
  return (
    <div className={classNames('td', className)} {...otherProps}>
      {props.children}
    </div>
  );
}
