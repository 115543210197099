import React from 'react';
const ScrollContext = React.createContext(0);

export default ScrollContext;

export const withScrollContext = (Component) => {
  return (props) => (
    <ScrollContext.Consumer>
      {({scrollPosition, offset, direction}) =>
        <Component {...props}
                   scrollPosition={scrollPosition}
                   offset={offset}
                   direction = {direction}
      />}
    </ScrollContext.Consumer>
  )
};
