import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

const Button = props => {
  let ComponentName;

  if(props.to){
    ComponentName = Link;
  }else if(props.mailto) {
    ComponentName = 'a'
  }else{
    ComponentName = 'button';
  }

  const type = props.type ? props.type : 'primary';
  const color = props.color ? props.color : 'black';
  const { to, mailto } = props;
  return (
    <ComponentName
      {...props}
      type={!to ? 'submit' : undefined}
      href={mailto ? `mailto:${mailto}` : undefined}
      className={classNames('button',
        `button--${type}`,
        `button--${color}`,
        props.size && `button--${props.size}`,
        props.className
      )}
      disabled={props.disabled}>
      {props.children}
    </ComponentName>
  )
}

export const BackButton = withRouter(props => <Button type="primary" color="grey" size="small" className="back" onClick={() => props.history.goBack()}>zurück</Button>)


export default Button;
