import { merge } from '../../helpers.js';

const userPropertiesState = {
  loading: false,
  error: false,
  properties: []
};

export default (state = userPropertiesState, action) => {
  switch(action.type){
    // FETCH USER PROPERTIES
    case 'FETCH_ALL_PROPERTIES_REQUEST':
      return {
        ...state,
        loading: true,
        error: false,
      };
    case 'FETCH_ALL_PROPERTIES_SUCCESS':
      return {
        ...state,
        properties: action.properties,
        error: false,
        loading: false
      };
    case 'FETCH_ALL_PROPERTIES_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case 'FETCH_USER_PROPERTIES_REQUEST':
      return {
        ...state,
        loading: true,
        error: false,
      };
    case 'FETCH_USER_PROPERTIES_SUCCESS':
      const properties = merge(state.properties, action.properties);

      return {
        ...state,
        properties,
        error: false,
        loading: false
      };
    case 'FETCH_USER_PROPERTIES_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error
      };

    // FETCH USER PROPERTY
    case 'FETCH_USER_PROPERTY_REQUEST':
      return {
        ...state,
        loading: true,
        error: false
      };
    case 'FETCH_USER_PROPERTY_SUCCESS': {
      // TODO: review
      const properties = state.properties.filter(property => property._id !== action.property._id)

      return {
        ...state,
        properties: [
          ...properties,
          action.property
        ],
        loading: false,
        error:false
      };
    }
    case 'FETCH_USER_PROPERTY_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error
      };

    // CREATE USER PROPERTY
    case 'CREATE_USER_PROPERTY_REQUEST':
      return {
        ...state,
        loading: true,
        error: false
      };
    case 'CREATE_USER_PROPERTY_SUCCESS':
      return {
        ...state,
        properties: [
          ...state.properties,
          action.property
        ],
        loading: false,
        error: false
      };
    case 'CREATE_USER_PROPERTY_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error
      };

      // DELETE USER PROPERTY
    case 'DELETE_USER_PROPERTY_REQUEST':
      return {
        ...state,
        loading: true,
        error: false
      };
    case 'DELETE_USER_PROPERTY_SUCCESS': {
      const properties = state.properties.filter(property => property._id !== action.property._id);

      return {
        ...state,
        properties,
        loading: false,
        error: false
      };
    }
    case 'DELETE_USER_PROPERTY_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error
      };

    // FETCH PROPERTY ACTIVITIES
    case 'FETCH_PROPERTY_ACTIVITIES_REQUEST':
      return {
        ...state,
        loading: true,
        error: false
      };
    case 'FETCH_PROPERTY_ACTIVITIES_SUCCESS': {
      let properties = state.properties.map(property => {
        if(property._id === action.propertyId){
          return {
            ...property,
            activities: action.activities
          }
        }else{
          return property
        }
      });

      return {
        ...state,
        properties,
        loading: false,
        error: false
      };
    }
    case 'FETCH_PROPERTY_ACTIVITIES_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
